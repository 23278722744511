import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenu from "../components/BurgerMenu";
import PortalPopup from "../components/PortalPopup";
import styles from "./DesktopAbout.module.css";
const DesktopAbout = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onSergiuPainterDecoratingClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  const openBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(true);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  const onContactUsButtonClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  return (
    <>
      <div className={styles.desktopAbout}>
        <div className={styles.topSection}>
          <header className={styles.headerAndHero}>
            <header className={styles.header}>
              <nav className={styles.headerContents}>
                <Link
                  className={styles.sergiuPainterDecoratingContainer}
                  to="/"
                  onClick={onSergiuPainterDecoratingClick}
                >
                  <p className={styles.sergiu}>
                    <span>
                      <span className={styles.sergiu1}>Sergiu</span>
                    </span>
                    <span>
                      <span>{` `}</span>
                    </span>
                  </p>
                  <p className={styles.painterDecorating}>
                    <span>
                      <span>painter decorating</span>
                    </span>
                  </p>
                </Link>
                <div className={styles.headerLinks}>
                  <Link className={styles.home} to="/" onClick={onHomeClick}>
                    Home
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-11.svg"
                  />
                  <a className={styles.about}>About</a>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-21.svg"
                  />
                  <Link
                    className={styles.services}
                    to="/desktop-services"
                    onClick={onServicesClick}
                  >
                    Services
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-51.svg"
                  />
                  <Link
                    className={styles.workExamples}
                    to="/desktop-work-examples"
                    onClick={onWorkExamplesClick}
                  >
                    Work Examples
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-6.svg"
                  />
                  <Link
                    className={styles.contact}
                    to="/desktop-contact"
                    onClick={onContactClick}
                  >
                    Contact
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-7.svg"
                  />
                </div>
                <img
                  className={styles.frameIcon}
                  alt=""
                  src="/frame2.svg"
                  onClick={openBurgerMenu}
                />
              </nav>
            </header>
            <div className={styles.hero}>
              <div className={styles.about1}>About</div>
            </div>
          </header>
          <div className={styles.contents}>
            <div className={styles.aboutArticle}>
              <p className={styles.sergiuPainterDecorating}>
                Sergiu Painter Decorating owned and operated by Sergiu Leancă
                who has been running painting businesses for Almost 10 years.
              </p>
              <p className={styles.sergiuPainterDecorating1}>
                Sergiu painter decorating has many years’ experience in painting
                residential properties, commercial buildings, new builds,
                wallpaper hanging and restoration of wooden windows for heritage
                buildings. We prepare every job thoroughly to ensure the best
                possible finish.
              </p>
              <div className={styles.articleRectagle1} />
              <p className={styles.sergiuPainterDecoratingContainer1}>
                <span className={styles.span}>
                  Sergiu Painter Decorating boasts a reliable team of skilled
                  painters accustomed to delivering top-notch results. With
                  extensive experience of over 10 years, we have mastered
                  various painting techniques on diverse surfaces, ensuring
                  exceptional outcomes for every project we undertake.
                </span>
                <span className={styles.span}></span>
                <span className={styles.span}>
                  What sets us apart? At Sergiu Painter Decorating, we firmly
                  believe that thorough preparation is the key to achieving
                  outstanding results. We invest time in properly preparing the
                  surfaces before commencing our work. Moreover, we exclusively
                  use premium materials on all our projects to guarantee the
                  finest finish possible.
                </span>
                <span className={styles.span}></span>
                <span className={styles.span}>
                  As a company, we take pride in upholding values such as trust,
                  loyalty, professionalism, safety, and security. Rest assured,
                  we are fully insured to provide you with peace of mind
                  throughout the process. Upon project completion, our dedicated
                  team will ensure a thorough cleanup of the area, leaving your
                  space immaculate and ready to enjoy.
                </span>
              </p>
              <div className={styles.articleRectagle2} />
            </div>
            <div className={styles.contentsLogo}>
              <div className={styles.sergiuPainterDecoratingContainer2}>
                <span className={styles.sergiu2}>{`Sergiu `}</span>
                <span className={styles.painterDecorating1}>
                  painter decorating
                </span>
              </div>
              <div className={styles.rectangleUnderlogo} />
            </div>
          </div>
        </div>
        <div className={styles.contactAndFooterSection}>
          <div className={styles.contactUsSection}>
            <div className={styles.contactUsInfoBox}>
              <div className={styles.contactUsInfo}>
                <div className={styles.contactUs}>Contact us</div>
                <div className={styles.pleaseShareYour}>
                  Please share your details below and we will get in touch with
                  you soon.
                </div>
              </div>
              <button
                className={styles.contactUsButton}
                autoFocus
                onClick={onContactUsButtonClick}
              >
                <div className={styles.contactUs1}>Contact us</div>
              </button>
            </div>
            <img
              className={styles.contactUsImageIcon}
              alt=""
              src="/image-5@2x.png"
            />
          </div>
          <footer className={styles.footer}>
            <div className={styles.footerInfoSection}>
              <div className={styles.footerDescription}>
                <div className={styles.sergiuPainterDecorating3}>
                  Sergiu painter decorating
                </div>
                <div className={styles.sergiuPainterDecorating4}>
                  Sergiu Painter Decorating is a registered Painting and
                  Decorating business with 10 years of experience. Paint Perfect
                  is a family run business. Based in Cashel co. tipperary.
                </div>
              </div>
              <div className={styles.footerContactInfo}>
                <div className={styles.contact1}>{`Contact `}</div>
                <div className={styles.location}>
                  <img
                    className={styles.iconLocation}
                    alt=""
                    src="/iconlocation2.svg"
                  />
                  <div className={styles.cashelCoTipperary}>
                    Cashel Co Tipperary |
                  </div>
                  <div className={styles.cashelCoTipperary}>
                    Dublin
                  </div>
                </div>
                <div className={styles.location}>
                  <img
                    className={styles.vectorIcon1}
                    alt=""
                    src="/vector6.svg"
                  />
                  <div className={styles.cashelCoTipperary}>0894958658</div>
                  <div className={styles.cashelCoTipperary}>0894389979</div>
                </div>
                <div className={styles.mail}>
                  <img
                    className={styles.vectorIcon2}
                    alt=""
                    src="/vector7.svg"
                  />
                  <div className={styles.cashelCoTipperary}>
                    SergiuLeanca.29@icloud.com
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footerBottomBar}>
              <div className={styles.sergiuPainterDecorating5}>
                Sergiu Painter Decorating Copyright © 2023
              </div>
            </div>
          </footer>
        </div>
      </div>
      {isBurgerMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeBurgerMenu}
        >
          <BurgerMenu onClose={closeBurgerMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DesktopAbout;
