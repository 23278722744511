import { useMemo } from "react";
import styles from "./FormCommercialContainer.module.css";
const FormCommercialContainer = ({
  imageDimensions,
  carDescription,
  propJustifyContent,
  propPadding,
  propZIndex,
  onWorkExamplesButton1Click,
}) => {
  const workExamplesBox1Style = useMemo(() => {
    return {
      justifyContent: propJustifyContent,
    };
  }, [propJustifyContent]);

  const workExamplesButton1Style = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const commercialStyle = useMemo(() => {
    return {
      zIndex: propZIndex,
    };
  }, [propZIndex]);

  return (
    <div className={styles.workExamplesBox1} style={workExamplesBox1Style}>
      <img
        className={styles.workExamplesImage1Icon}
        alt=""
        src={imageDimensions}
      />
      <button
        className={styles.workExamplesButton1}
        autoFocus
        onClick={onWorkExamplesButton1Click}
        style={workExamplesButton1Style}
      >
        <div className={styles.commercial} style={commercialStyle}>
          {carDescription}
        </div>
      </button>
    </div>
  );
};

export default FormCommercialContainer;
