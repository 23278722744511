import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenu from "../components/BurgerMenu";
import PortalPopup from "../components/PortalPopup";
import FormCommercialContainer from "../components/FormCommercialContainer";
import styles from "./DesktopHome.module.css";
const DesktopHome = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const Comments={
    comment1: '"Prompt and professional. Sergiu pays great attention to detail and his work cannot be faulted. Very responsive and a pleasure to do business with, nothing is too much trouble and very helpful with his advice. Highly recommended."',
    comment2: '"Absolutely thrilled with the house painting service provided by Sergiu! I must say that Sergiu and his team were prompt, professional, and their attention to detail was impeccable. They transformed our home beautifully, and their responsiveness and helpful advice made the process smooth and stress-free. Highly recommended!"',
    comment3: '"I couldn`t be happier with the house painter agency led by Sergiu. Their commitment to excellence is unmatched. They lived up to their reputation as being professional, and detail-oriented. Sergiu made sure every aspect of the project was flawless. I wholeheartedly recommend their services to anyone looking for top-notch house painting."'
  }
  const Authors={
    author1: "Liam Kelly",
    author2: "Cian Byrne",
    author3: "Declan O'Connor"
  }

  const [workExampleBG_1, setBackgroundImage_1] = useState('url(/commercial2.jpg)')
  const [workExampleBG_2, setBackgroundImage_2] = useState('url(/workexamplecarousel2@3x.png)')
  const [workExampleBG_3, setBackgroundImage_3] = useState('url(/servicesarticleimage3@2x.png)')
  const BGImages_1 = {
    workExampleCarouselImageURL_1: 'url(/servicesarticleimage1@2x.png)',
    workExampleCarouselImageURL_2: 'url(/commercial2.jpg)',
    workExampleCarouselImageURL_3: 'url(/commercial3.jpg)'
  }
  const BGImages_2 = {
    workExampleCarouselImageURL_1: 'url(/workexamplecarousel2@3x.png)',
    workExampleCarouselImageURL_2: 'url(/residential2.jpg)',
    workExampleCarouselImageURL_3: 'url(/residential3.jpg)'
  }
  const BGImages_3 = {
    workExampleCarouselImageURL_1: 'url(/servicesarticleimage3@2x.png)',
    workExampleCarouselImageURL_2: 'url(/indoors2.jpg)',
    workExampleCarouselImageURL_3: 'url(/indoors3.jpg)',
    workExampleCarouselImageURL_4: 'url(/workexamplesimage31.jpg)'
  }

  const changeImgOnClick_1_Right = ()=>{
    if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_1) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_3)
    else if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_2) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_1)
    else if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_3) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_2)
  }
  const changeImgOnClick_1_Left = ()=>{
    if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_1) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_2)
    else if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_2) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_3)
    else if(workExampleBG_1 == BGImages_1.workExampleCarouselImageURL_3) setBackgroundImage_1(BGImages_1.workExampleCarouselImageURL_1)
  }

  const changeImgOnClick_2_Right = ()=>{
    if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_1) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_3)
    else if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_2) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_1)
    else if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_3) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_2)
  }
  const changeImgOnClick_2_Left = ()=>{
    if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_1) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_2)
    else if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_2) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_3)
    else if(workExampleBG_2 == BGImages_2.workExampleCarouselImageURL_3) setBackgroundImage_2(BGImages_2.workExampleCarouselImageURL_1)
  }

  const changeImgOnClick_3_Right = ()=>{
    if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_1) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_3)
    else if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_2) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_1)
    else if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_3) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_2)
  }
  const changeImgOnClick_3_Left = ()=>{
    if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_1) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_2)
    else if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_2) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_3)
    else if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_3) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_4)
    else if(workExampleBG_3 == BGImages_3.workExampleCarouselImageURL_4) setBackgroundImage_3(BGImages_3.workExampleCarouselImageURL_1)
  }

  const onWorkExamplesButton1Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesButton2Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesButton3Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onSergiuPainterDecoratingClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/desktop-about");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  const openBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(true);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  const onHeroButtonClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onQualityIconClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onExpIconClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onMoneyIconClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onContactUsButtonClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  function changeCommentRight(){
    var comment = document.getElementById("Comment")
    var author = document.getElementById("Author")
    if(author.innerHTML==Authors.author1) author.innerHTML = Authors.author2
    else if(author.innerHTML==Authors.author2) author.innerHTML = Authors.author3
    else if(author.innerHTML==Authors.author3) author.innerHTML = Authors.author1

    if(comment.innerHTML==Comments.comment1) comment.innerHTML = Comments.comment2
    else if(comment.innerHTML==Comments.comment2) comment.innerHTML = Comments.comment3
    else if(comment.innerHTML==Comments.comment3) comment.innerHTML = Comments.comment1
  }
  function changeCommentLeft(){
    var comment = document.getElementById("Comment")
    var author = document.getElementById("Author")
    if(author.innerHTML==Authors.author1) author.innerHTML = Authors.author3
    else if(author.innerHTML==Authors.author2) author.innerHTML = Authors.author1
    else if(author.innerHTML==Authors.author3) author.innerHTML = Authors.author2

    if(comment.innerHTML==Comments.comment1) comment.innerHTML = Comments.comment3
    else if(comment.innerHTML==Comments.comment2) comment.innerHTML = Comments.comment1
    else if(comment.innerHTML==Comments.comment3) comment.innerHTML = Comments.comment2
  }
  return (
    <>
      <div className={styles.desktopHome}>
        <div className={styles.headerAndMain}>
          <div className={styles.top}>
            <header className={styles.header}>
              <nav className={styles.headerContents}>
                <Link
                  className={styles.sergiuPainterDecoratingContainer}
                  to="/"
                  onClick={onSergiuPainterDecoratingClick}
                >
                  <p className={styles.sergiu}>
                    <span>
                      <span className={styles.sergiu1}>Sergiu</span>
                    </span>
                    <span>
                      <span>{` `}</span>
                    </span>
                  </p>
                  <p className={styles.painterDecorating}>
                    <span>
                      <span>painter decorating</span>
                    </span>
                  </p>
                </Link>
                <div className={styles.headerLinks}>
                  <Link className={styles.home} to="/" onClick={onHomeClick}>
                    Home
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-1.svg"
                  />
                  <a className={styles.about} onClick={onAboutClick}>
                    About
                  </a>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-2.svg"
                  />
                  <Link
                    className={styles.services}
                    to="/desktop-services"
                    onClick={onServicesClick}
                  >
                    Services
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-5.svg"
                  />
                  <Link
                    className={styles.workExamples}
                    to="/desktop-work-examples"
                    onClick={onWorkExamplesClick}
                  >
                    Work Examples
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-6.svg"
                  />
                  <Link
                    className={styles.contact}
                    to="/desktop-contact"
                    onClick={onContactClick}
                  >
                    Contact
                  </Link>
                  <img
                    className={styles.headerLinksChild}
                    alt=""
                    src="/vector-7.svg"
                  />
                </div>
                <img
                  className={styles.frameIcon}
                  alt=""
                  src="/frame.svg"
                  onClick={openBurgerMenu}
                />
              </nav>
            </header>
            <div className={styles.hero}>
              <div className={styles.heroContainer}>
                <div className={styles.heroTitle}>
                  <div
                    className={styles.professionalResidential}
                  >{`Professional Residential & Commercial Painter`}</div>
                  <div className={styles.heroRectangle} />
                </div>
                <div className={styles.heroInfobox}>
                  <h1 className={styles.whetherItsYour}>
                    We offer house Painter and Decorating services in Cashel co. Tipperary and Dublin for individuals and businesses.
                    Whether it's your home or business in need of a refresh, a
                    fresh coat of paint is the ideal solution. It has the power
                    to completely transform and enhance your space, and our
                    skilled team is well-equipped to bring this transformation
                    to life. With 10 years of experience, Sergiu ensures
                    professional results. Let us handle the painting process, so
                    you can enjoy the results hassle-free!
                  </h1>
                  <Link
                    className={styles.heroButton}
                    to="/desktop-services"
                    onClick={onHeroButtonClick}
                  >
                    <div className={styles.learnMore}>Learn More</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <main className={styles.main}>
            <section className={styles.workExamplesSection}>
              <FormCommercialContainer
                imageDimensions="/workexamplesimage1@2x.png"
                carDescription="Commercial"
                onWorkExamplesButton1Click={onWorkExamplesButton1Click}
              />
              <FormCommercialContainer
                imageDimensions="/workexamplesimage2@2x.png"
                carDescription="Exterior"
                propJustifyContent="flex-end"
                propPadding="var(--padding-3xs) 84px"
                propZIndex="unset"
                onWorkExamplesButton1Click={onWorkExamplesButton2Click}
              />
              <FormCommercialContainer
                imageDimensions="/workexamplesimage3@2x.png"
                carDescription="interior"
                propJustifyContent="flex-end"
                propPadding="var(--padding-3xs) 86px"
                propZIndex="unset"
                onWorkExamplesButton1Click={onWorkExamplesButton3Click}
              />
            </section>
            <section className={styles.whyChooseUsSection}>
              <div className={styles.whyChooseUsTitleSection}>
                <div className={styles.whyChooseUs}>Why choose us?</div>
                <div className={styles.whyChooseUsTitleSectionChild} />
              </div>
              <div className={styles.whyChooseUsInfoBoxes}>
                <div className={styles.wcuInfoBox1}>
                  <img
                    className={styles.qualityicon}
                    alt=""
                    src="/qualityicon.svg"
                    onClick={onQualityIconClick}
                  />
                  <div className={styles.highWorkQuality}>
                    High work quality
                  </div>
                </div>
                <div className={styles.wcuInfoBox2}>
                  <img
                    className={styles.qualityicon}
                    alt=""
                    src="/expicon.svg"
                    onClick={onExpIconClick}
                  />
                  <div className={styles.highWorkQuality}>
                    10 years of experience
                  </div>
                </div>
                <div className={styles.wcuInfoBox3}>
                  <img
                    className={styles.moneyicon}
                    alt=""
                    src="/moneyicon.svg"
                    onClick={onMoneyIconClick}
                  />
                  <div className={styles.competitivePrices}>
                    competitive prices
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.workExamples2}>
              <div className={styles.whyChooseUs}>Work Examples</div>
              <div className={styles.whyChooseUsTitleSectionChild} />
              <div className={styles.workExampleCarousels}>
                <div className={styles.workExampleBox1}>
                  <div className={styles.workExampleCarousel1} style={{backgroundImage: workExampleBG_1}}>
                    <div className={styles.arrows}>
                      <button
                        className={styles.arrowleft}
                        autoFocus
                        id="ImgLeft"
                        onClick={changeImgOnClick_1_Left}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector.svg"
                        />
                      </button>
                      <button
                        className={styles.arrowleft}
                        autoFocus
                        id="ImgRight"
                        onClick={changeImgOnClick_1_Right}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector1.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div className={styles.workExampleRectangle1} />
                </div>
                <div className={styles.workExampleBox2}>
                  <div className={styles.workExampleCarousel2} style={{backgroundImage: workExampleBG_2}}>
                    <div className={styles.arrows}>
                      <button
                        className={styles.arrowleft1}
                        autoFocus
                        id="ImgLeft"
                        onClick={changeImgOnClick_2_Left}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector2.svg"
                        />
                      </button>
                      <button
                        className={styles.arrowright1}
                        autoFocus
                        id="ImgRight"
                        onClick={changeImgOnClick_2_Right}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector3.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div className={styles.workExampleRectangle1} />
                </div>
                <div className={styles.workExampleBox3}>
                  <div className={styles.workExampleCarousel3} style={{backgroundImage: workExampleBG_3}}>
                    <div className={styles.arrows}>
                      <button
                        className={styles.arrowright1}
                        autoFocus
                        id="ImgLeft"
                        onClick={changeImgOnClick_3_Right}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector4.svg"
                        />
                      </button>
                      <button
                        className={styles.arrowright1}
                        autoFocus
                        id="ImgRight"
                        onClick={changeImgOnClick_3_Left}
                      >
                        <img
                          className={styles.vectorIcon1}
                          alt=""
                          src="/vector5.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div className={styles.workExampleRectangle1} />
                </div>
              </div>
            </section>
          </main>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.comments}>
            <div
              className={styles.promptProfessional} id="Comment"
            >"Prompt and professional. Sergiu pays great attention to detail and his work cannot be faulted. Very responsive and a pleasure to do business with, nothing is too much trouble and very helpful with his advice. Highly recommended."</div>
            <div className={styles.underCommentSection}>
              <div className={styles.commentButtonsSection}>
                <div className={styles.commentsRectangle} />
                <div className={styles.commentChangeButtons}>
                  <button className={styles.commentChangeButtonLeft}
                  onClick={changeCommentLeft}>
                    <div className={styles.commentChangeButtonLeftChild} />
                    <img
                      className={styles.fill35Icon}
                      alt=""
                      src="/fill35.svg"
                    />
                  </button>
                  <button className={styles.commentChangeButtonLeft}
                      onClick={changeCommentRight}>
                    <div className={styles.commentChangeButtonLeftChild} />
                    <img
                      className={styles.fill35Icon1}
                      alt=""
                      src="/fill351.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.cindyVanoosten} id="Author">Liam Kelly</div>
            </div>
          </div>
          <div className={styles.contactAndFooterSection}>
            <div className={styles.contactUsSection}>
              <div className={styles.contactUsInfoBox}>
                <div className={styles.contactUsInfo}>
                  <div className={styles.contactUs}>Contact us</div>
                  <div className={styles.pleaseShareYour}>
                    Please share your details below and we will get in touch
                    with you soon.
                  </div>
                </div>
                <button
                  className={styles.contactUsButton}
                  autoFocus
                  onClick={onContactUsButtonClick}
                >
                  <div className={styles.contactUs1}>Contact us</div>
                </button>
              </div>
              <img
                className={styles.contactUsImageIcon}
                alt=""
                src="/image-5@2x.png"
                id="testt"
              />
            </div>
            <footer className={styles.footer}>
              <div className={styles.footerInfoSection}>
                <div className={styles.footerDescription}>
                  <div className={styles.sergiuPainterDecorating}>
                    Sergiu painter decorating
                  </div>
                  <div className={styles.sergiuPainterDecorating1}>
                    Sergiu Painter Decorating is a registered Painting and
                    Decorating business with 10 years of experience. Paint
                    Perfect is a family run business. Based in Cashel co.
                    tipperary.
                  </div>
                </div>
                <div className={styles.footerContactInfo}>
                  <div className={styles.contact1}>{`Contact `}</div>
                  <div className={styles.location}>
                    <img
                      className={styles.iconLocation}
                      alt=""
                      src="/iconlocation.svg"
                    />
                    <div className={styles.cashelCoTipperary}>
                      Cashel Co Tipperary |
                    </div>
                    <div className={styles.cashelCoTipperary}>
                      Dublin
                    </div>
                  </div>
                  <div className={styles.location}>
                    <img
                      className={styles.vectorIcon7}
                      alt=""
                      src="/vector6.svg"
                    />
                    <div className={styles.cashelCoTipperary}>0894958658</div>
                    <div className={styles.cashelCoTipperary}>0894389979</div>
                  </div>
                  <div className={styles.mail}>
                    <img
                      className={styles.vectorIcon8}
                      alt=""
                      src="/vector7.svg"
                    />
                    <div className={styles.cashelCoTipperary}>
                      SergiuLeanca.29@icloud.com
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footerBottomBar}>
                <div className={styles.sergiuPainterDecorating2}>
                  Sergiu Painter Decorating Copyright © 2023
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
      {isBurgerMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeBurgerMenu}
        >
          <BurgerMenu onClose={closeBurgerMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DesktopHome;
