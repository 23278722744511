import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenu from "../components/BurgerMenu";
import PortalPopup from "../components/PortalPopup";
import styles from "./DesktopWorkExamples.module.css";
const DesktopWorkExamples = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onSergiuPainterDecoratingClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/desktop-about");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  const openBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(true);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  const onWorkExamplesRectangleClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesRectangle1Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesRectangle2Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesRectangle11Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesRectangle21Click = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onContactUsButtonClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  function changeImg1(e){
    if(e.target.getAttribute('src') == '/workexamplesimage11@2x.png') e.target.setAttribute('src','/workexamplesimage3@2x.png');
    else if(e.target.getAttribute('src') == '/workexamplesimage3@2x.png') e.target.setAttribute('src','/workexamplesimage11@2x.png')
  }
  function changeImg2(e){
    if(e.target.getAttribute('src') == '/workexamplesimage21@2x.png') e.target.setAttribute('src','/servicesarticleimage2@2x.png');
    else if(e.target.getAttribute('src') == '/servicesarticleimage2@2x.png') e.target.setAttribute('src','/workexamplesimage21@2x.png')
  }
  function changeImg3(e){
    if(e.target.getAttribute('src') == '/workexamplesimage12@2x.png') e.target.setAttribute('src','/servicesarticleimage1@2x.png');
    else if(e.target.getAttribute('src') == '/servicesarticleimage1@2x.png') e.target.setAttribute('src','/workexamplesimage12@2x.png')
  }
  function changeImg4(e){
    if(e.target.getAttribute('src') == '/workexamplesimage22@2x.png') e.target.setAttribute('src','/servicesarticleimage3@2x.png');
    else if(e.target.getAttribute('src') == '/servicesarticleimage3@2x.png') e.target.setAttribute('src','/workexamplesimage22@2x.png')
  }
  function changeImg5(e){
    if(e.target.getAttribute('src') == '/workexamplesimage32.jpg') e.target.setAttribute('src','/workexamplesimage31.jpg');
    else if(e.target.getAttribute('src') == '/workexamplesimage31.jpg') e.target.setAttribute('src','/workexamplesimage32.jpg')
  }

  return (
    <>
      <div className={styles.desktopWorkExamples}>
        <header className={styles.headerAndHero}>
          <header className={styles.header}>
            <nav className={styles.headerContents}>
              <Link
                className={styles.sergiuPainterDecoratingContainer}
                to="/"
                onClick={onSergiuPainterDecoratingClick}
              >
                <p className={styles.sergiu}>
                  <span>
                    <span className={styles.sergiu1}>Sergiu</span>
                  </span>
                  <span>
                    <span>{` `}</span>
                  </span>
                </p>
                <p className={styles.painterDecorating}>
                  <span>
                    <span>painter decorating</span>
                  </span>
                </p>
              </Link>
              <div className={styles.headerLinks}>
                <Link className={styles.home} to="/" onClick={onHomeClick}>
                  Home
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-11.svg"
                />
                <a className={styles.about} onClick={onAboutClick}>
                  About
                </a>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-21.svg"
                />
                <Link
                  className={styles.services}
                  to="/desktop-services"
                  onClick={onServicesClick}
                >
                  Services
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-51.svg"
                />
                <Link
                  className={styles.workExamples}
                  to="/desktop-work-examples"
                  onClick={onWorkExamplesClick}
                >
                  Work Examples
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-6.svg"
                />
                <Link
                  className={styles.contact}
                  to="/desktop-contact"
                  onClick={onContactClick}
                >
                  Contact
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-7.svg"
                />
              </div>
              <img
                className={styles.frameIcon}
                alt=""
                src="/frame1.svg"
                onClick={openBurgerMenu}
              />
            </nav>
          </header>
          <div className={styles.hero}>
            <div className={styles.workExamples1}>Work Examples</div>
          </div>
        </header>
        <main className={styles.workExamplesMain}>
          <div className={styles.workExamplesTitle}>
            <div
              className={styles.clickOnThe}
            >{`Click on the images to repair and decorate the interiors and exteriors! `}</div>
            <div
              className={styles.workExamplesRectangle}
              onClick={onWorkExamplesRectangleClick}
            />
          </div>
          <div className={styles.workExamplesContentsRow1}>
            <button className={styles.workExamplesImageCarousel1}>
              <img
                className={styles.workExamplesImage1Icon}
                alt=""
                src="/workexamplesimage11@2x.png"
                onClick={changeImg1}
              />
              <div
                className={styles.workExamplesRectangle1}
                onClick={onWorkExamplesRectangle1Click}
              />
            </button>
            <button className={styles.workExamplesImageCarousel1}>
              <img
                className={styles.workExamplesImage1Icon}
                alt=""
                src="/workexamplesimage21@2x.png"
                onClick={changeImg2}
              />
              <div
                className={styles.workExamplesRectangle1}
                onClick={onWorkExamplesRectangle2Click}
              />
            </button>
          </div>
          <div className={styles.workExamplesContentsRow1}>
            <button className={styles.workExamplesImageCarousel1}>
              <img
                className={styles.workExamplesImage1Icon}
                alt=""
                src="/workexamplesimage12@2x.png"
                onClick={changeImg3}
              />
              <div
                className={styles.workExamplesRectangle1}
                onClick={onWorkExamplesRectangle11Click}
              />
            </button>
            <button className={styles.workExamplesImageCarousel1}>
              <img
                className={styles.workExamplesImage1Icon}
                alt=""
                src="/workexamplesimage22@2x.png"
                onClick={changeImg4}
              />
              <div
                className={styles.workExamplesRectangle1}
                onClick={onWorkExamplesRectangle21Click}
              />
            </button>
          </div>
          <div className={styles.workExamplesContentsRow1}>
            <button className={styles.workExamplesImageCarousel1}>
              <img
                className={styles.workExamplesImage1Icon}
                alt=""
                src="/workexamplesimage32.jpg"
                onClick={changeImg5}
              />
              <div
                className={styles.workExamplesRectangle1}
                onClick={onWorkExamplesRectangle1Click}
              />
            </button>
          </div>
        </main>
        <div className={styles.contactAndFooterSection}>
          <div className={styles.contactUsSection}>
            <div className={styles.contactUsInfoBox}>
              <div className={styles.contactUsInfo}>
                <div className={styles.contactUs}>Contact us</div>
                <div className={styles.pleaseShareYour}>
                  Please share your details below and we will get in touch with
                  you soon.
                </div>
              </div>
              <button
                className={styles.contactUsButton}
                autoFocus
                onClick={onContactUsButtonClick}
              >
                <div className={styles.contactUs1}>Contact us</div>
              </button>
            </div>
            <img
              className={styles.contactUsImageIcon}
              alt=""
              src="/image-51@2x.png"
            />
          </div>
          <footer className={styles.footer}>
            <div className={styles.footerInfoSection}>
              <div className={styles.footerDescription}>
                <div className={styles.sergiuPainterDecorating}>
                  Sergiu painter decorating
                </div>
                <div className={styles.sergiuPainterDecorating1}>
                  Sergiu Painter Decorating is a registered Painting and
                  Decorating business with 10 years of experience. Paint Perfect
                  is a family run business. Based in Cashel co. tipperary.
                </div>
              </div>
              <div className={styles.footerContactInfo}>
                <div className={styles.contact1}>{`Contact `}</div>
                <div className={styles.location}>
                  <img
                    className={styles.iconLocation}
                    alt=""
                    src="/iconlocation1.svg"
                  />
                 <div className={styles.cashelCoTipperary}>
                    Cashel Co Tipperary |
                  </div>
                  <div className={styles.cashelCoTipperary}>
                    Dublin
                  </div>
                </div>
                <div className={styles.location}>
                  <img
                    className={styles.vectorIcon1}
                    alt=""
                    src="/vector6.svg"
                  />
                  <div className={styles.cashelCoTipperary}>0894958658</div>
                  <div className={styles.cashelCoTipperary}>0894389979</div>
                </div>
                <div className={styles.mail}>
                  <img
                    className={styles.vectorIcon2}
                    alt=""
                    src="/vector8.svg"
                  />
                  <div className={styles.cashelCoTipperary}>
                    SergiuLeanca.29@icloud.com
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footerBottomBar}>
              <div className={styles.sergiuPainterDecorating2}>
                Sergiu Painter Decorating Copyright © 2023
              </div>
            </div>
          </footer>
        </div>
      </div>
      {isBurgerMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeBurgerMenu}
        >
          <BurgerMenu onClose={closeBurgerMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DesktopWorkExamples;
