import { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenu from "../components/BurgerMenu";
import PortalPopup from "../components/PortalPopup";
import styles from "./DesktopServices.module.css";
const DesktopServices = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onSergiuPainterDecoratingClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/desktop-about");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  const openBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(true);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onContactUsButtonClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  return (
    <>
      <div className={styles.desktopServices}>
        <header className={styles.headerAndHero}>
          <header className={styles.header}>
            <nav className={styles.headerContents}>
              <Link
                className={styles.sergiuPainterDecoratingContainer}
                to="/"
                onClick={onSergiuPainterDecoratingClick}
              >
                <p className={styles.sergiu}>
                  <span>
                    <span className={styles.sergiu1}>Sergiu</span>
                  </span>
                  <span>
                    <span>{` `}</span>
                  </span>
                </p>
                <p className={styles.painterDecorating}>
                  <span>
                    <span>painter decorating</span>
                  </span>
                </p>
              </Link>
              <div className={styles.headerLinks}>
                <Link className={styles.home} to="/" onClick={onHomeClick}>
                  Home
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-11.svg"
                />
                <a className={styles.about} onClick={onAboutClick}>
                  About
                </a>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-22.svg"
                />
                <Link
                  className={styles.services}
                  to="/desktop-services"
                  onClick={onServicesClick}
                >
                  Services
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-51.svg"
                />
                <Link
                  className={styles.workExamples}
                  to="/desktop-work-examples"
                  onClick={onWorkExamplesClick}
                >
                  Work Examples
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-61.svg"
                />
                <Link
                  className={styles.contact}
                  to="/desktop-contact"
                  onClick={onContactClick}
                >
                  Contact
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-7.svg"
                />
              </div>
              <img
                className={styles.frameIcon}
                alt=""
                src="/frame2.svg"
                onClick={openBurgerMenu}
              />
            </nav>
          </header>
          <div className={styles.hero}>
            <div className={styles.services1}>services</div>
          </div>
        </header>
        <main className={styles.servicesArticle}>
          <div className={styles.servicesArticleSection1}>
            <div className={styles.servicesArticleText1}>
              <div className={styles.commercial}>commercial</div>
              <div className={styles.areYouConsideringContainer}>
                <p className={styles.sergiu}>
                  Sergiu painter decorating boasts extensive experience in
                  handling a wide range of commercial properties, such as
                  schools, large commercial buildings, fast food outlets, shops,
                  cafes, hotels, and apartment blocks. Our commitment to using
                  top-tier materials and advanced equipment guarantees superior
                  and impeccable outcomes.
                </p>
                <p className={styles.sergiu}>
                  Recognizing the importance of maintaining business continuity,
                  we take extra precautions to minimize disruptions during our
                  work. Our team ensures that all areas are appropriately
                  screened off, providing safe walking paths that adhere to
                  stringent health and safety standards.
                </p>
              </div>
            </div>
            <img
              className={styles.servicesArticleImage1Icon}
              alt=""
              src="/servicesarticleimage1@2x.png"
            />
          </div>
          <div className={styles.servicesArticleSection2}>
            <img
              className={styles.servicesArticleImage2Icon}
              alt=""
              src="/servicesarticleimage2@2x.png"
            />
            <div className={styles.servicesArticleText2}>
              <div className={styles.commercial}>Wall Papering</div>
              <div className={styles.areYouConsideringContainer}>
                <p className={styles.sergiu}>
                  Sergiu Painter Decorating has a decade of experience in
                  wallpapering. We prioritize thorough preparation, ensuring the
                  area is meticulously sanded and any imperfections are filled
                  before hanging your chosen wallpaper. Our goal is to keep
                  disruptions to a minimum during the process. Rest assured, our
                  dedicated team will leave the workspace clean and tidy upon
                  completion.
                </p>
                <p className={styles.sergiu}>
                  For inspiration, we invite you to explore the attached photos
                  showcasing a variety of wallpapers. Witness how these
                  wallpapers can effortlessly transform your entire room or
                  office, adding a touch of elegance and personality to your
                  space.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.servicesArticleSection3}>
            <div className={styles.servicesArticleText3}>
              <div className={styles.commercial}>Residential Painting</div>
              <div className={styles.areYouConsideringContainer}>
                <p className={styles.sergiu}>
                  Are you considering giving your home a fresh update? Look no
                  further than a coat of paint, the perfect solution to
                  transform and brighten your living space. While many people
                  may assume painting is a simple task, it's essential to
                  recognize the value of hiring a professional painter.
                </p>
                <p className={styles.sergiu}>&nbsp;</p>
                <p className={styles.sergiu}>
                  Sure, anyone can apply paint, but do you know which type of
                  paint suits each surface and why? Are you familiar with the
                  crucial pre-painting preparations needed to prevent issues
                  like bubbling and peeling over time? The process involves
                  numerous factors, including proper priming and preparation.
                </p>
                <p className={styles.sergiu}>&nbsp;</p>
                <p className={styles.sergiu}>
                  With our skilled team of painters, you can trust that we
                  possess the experience to handle all the necessary
                  preparations and treatments, resulting in a polished and
                  long-lasting finish. Your satisfaction is our priority, which
                  is why we'll walk you through the completed work, ensuring you
                  are 100% delighted with the final result. When you choose us,
                  your home will be in capable hands, and the outcomes will
                  stand the test of time.
                </p>
              </div>
            </div>
            <img
              className={styles.servicesArticleImage1Icon}
              alt=""
              src="/servicesarticleimage3@2x.png"
            />
          </div>
          <div
            className={styles.servicesArticleSection4}
            data-animate-on-scroll
          >
            <img
              className={styles.servicesArticleImage2Icon}
              alt=""
              src="/servicesarticleimage4@2x.png"
            />
            <div className={styles.servicesArticleText4}>
              <div className={styles.commercial}>Power Washing</div>
              <div className={styles.areYouConsideringContainer}>
                <p className={styles.sergiu}>
                  In addition to our expertise in wallpapering, Sergiu Painter
                  Decorating also offers exceptional house Power Washing
                  services. With our vast experience and commitment to quality,
                  we guarantee outstanding results that will leave your home
                  looking fresh and rejuvenated.
                </p>
                <p className={styles.sergiu}>&nbsp;</p>
                <p className={styles.sergiu}>
                  Our Power Washing process is meticulous, ensuring every
                  surface is thoroughly cleaned to remove dirt, grime, and other
                  accumulated debris. Whether it's the exterior walls, patio,
                  driveway, or any other outdoor area, we have the skills and
                  advanced equipment to make them shine like new.
                </p>
                <p className={styles.sergiu}>&nbsp;</p>
                <p className={styles.sergiu}>
                  After the Power Washing is complete, you can rest assured that
                  your property will be left spotless and tidy, as cleanliness
                  is always a priority for us. We believe in providing top-notch
                  services that meet and exceed your expectations, ensuring your
                  satisfaction with the final results.
                </p>
              </div>
            </div>
          </div>
        </main>
        <div className={styles.banner}>
          <div className={styles.bannerTitle}>
            <div className={styles.allWorkGuaranteed}>All Work Guaranteed</div>
            <div className={styles.separationRect} />
          </div>
          <img
            className={styles.bannerImageIcon}
            alt=""
            src="/bannerimage@2x.png"
          />
          <div className={styles.bottomRectangle} />
        </div>
        <div className={styles.contactAndFooterSection}>
          <div className={styles.contactUsSection}>
            <div className={styles.contactUsInfoBox}>
              <div className={styles.contactUsInfo}>
                <div className={styles.contactUs}>Contact us</div>
                <div className={styles.pleaseShareYour}>
                  Please share your details below and we will get in touch with
                  you soon.
                </div>
              </div>
              <button
                className={styles.contactUsButton}
                autoFocus
                onClick={onContactUsButtonClick}
              >
                <div className={styles.contactUs1}>Contact us</div>
              </button>
            </div>
            <img
              className={styles.contactUsImageIcon}
              alt=""
              src="/image-5@2x.png"
            />
          </div>
          <footer className={styles.footer}>
            <div className={styles.footerInfoSection}>
              <div className={styles.footerDescription}>
                <div className={styles.sergiuPainterDecorating2}>
                  Sergiu painter decorating
                </div>
                <div className={styles.sergiuPainterDecorating3}>
                  Sergiu Painter Decorating is a registered Painting and
                  Decorating business with 10 years of experience. Paint Perfect
                  is a family run business. Based in Cashel co. tipperary.
                </div>
              </div>
              <div className={styles.footerContactInfo}>
                <div className={styles.contact1}>{`Contact `}</div>
                <div className={styles.location}>
                  <img
                    className={styles.iconLocation}
                    alt=""
                    src="/iconlocation.svg"
                  />
                  <div className={styles.cashelCoTipperary}>
                    Cashel Co Tipperary |
                  </div>
                  <div className={styles.cashelCoTipperary}>
                    Dublin
                  </div>
                </div>
                <div className={styles.location}>
                  <img
                    className={styles.vectorIcon1}
                    alt=""
                    src="/vector6.svg"
                  />
                  <div className={styles.cashelCoTipperary}>0894958658</div>
                  <div className={styles.cashelCoTipperary}>0894389979</div>
                </div>
                <div className={styles.mail}>
                  <img
                    className={styles.vectorIcon2}
                    alt=""
                    src="/vector7.svg"
                  />
                  <div className={styles.cashelCoTipperary}>
                    SergiuLeanca.29@icloud.com
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footerBottomBar}>
              <div className={styles.sergiuPainterDecorating4}>
                Sergiu Painter Decorating Copyright © 2023
              </div>
            </div>
          </footer>
        </div>
      </div>
      {isBurgerMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeBurgerMenu}
        >
          <BurgerMenu onClose={closeBurgerMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DesktopServices;
