import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenu from "../components/BurgerMenu";
import PortalPopup from "../components/PortalPopup";
import styles from "./DesktopContact.module.css";
const DesktopContact = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onSergiuPainterDecoratingClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/desktop-about");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  const openBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(true);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  return (
    <>
      <div className={styles.desktopContact}>
        <header className={styles.headerAndHero}>
          <header className={styles.header}>
            <nav className={styles.headerContents}>
              <Link
                className={styles.sergiuPainterDecoratingContainer}
                to="/"
                onClick={onSergiuPainterDecoratingClick}
              >
                <p className={styles.sergiu}>
                  <span>
                    <span className={styles.sergiu1}>Sergiu</span>
                  </span>
                  <span>
                    <span>{` `}</span>
                  </span>
                </p>
                <p className={styles.painterDecorating}>
                  <span>
                    <span>painter decorating</span>
                  </span>
                </p>
              </Link>
              <div className={styles.headerLinks}>
                <Link className={styles.home} to="/" onClick={onHomeClick}>
                  Home
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-11.svg"
                />
                <a className={styles.about} onClick={onAboutClick}>
                  About
                </a>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-23.svg"
                />
                <Link
                  className={styles.services}
                  to="/desktop-services"
                  onClick={onServicesClick}
                >
                  Services
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-52.svg"
                />
                <Link
                  className={styles.workExamples}
                  to="/desktop-work-examples"
                  onClick={onWorkExamplesClick}
                >
                  Work Examples
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-62.svg"
                />
                <Link
                  className={styles.contact}
                  to="/desktop-contact"
                  onClick={onContactClick}
                >
                  Contact
                </Link>
                <img
                  className={styles.headerLinksChild}
                  alt=""
                  src="/vector-7.svg"
                />
              </div>
              <img
                className={styles.frameIcon}
                alt=""
                src="/frame2.svg"
                onClick={openBurgerMenu}
              />
            </nav>
          </header>
          <div className={styles.hero}>
            <div className={styles.contact1}>Contact</div>
          </div>
        </header>
        <div className={styles.mainInfoSection}>
          <div className={styles.mainDescription}>
            <div className={styles.sergiuPainterDecorating}>
              Sergiu painter decorating
            </div>
            <div className={styles.sergiuPainterDecoratingContainer1}>
              <p className={styles.sergiu}>
                Sergiu Painter Decorating is a registered Painting and
                Decorating business with 10 years of experience. Paint Perfect
                is a family run business. Based in Cashel co. tipperary.
              </p>
              <p className={styles.sergiu}>&nbsp;</p>
              <p className={styles.sergiu}>
                Sergiu painter decorating has many years’ experience in painting
                residential properties, commercial buildings, new builds,
                wallpaper hanging and restoration of wooden windows for heritage
                buildings. We prepare every job thoroughly to ensure the best
                possible finish.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.contactMain}>
          <div className={styles.contactSection1}>
            <img
              className={styles.contactSectionIcon1}
              alt=""
              src="/contactsectionicon1.svg"
            />
            <div className={styles.contactSectionText1}>
              <div className={styles.callUs}>Call Us</div>
              <div className={styles.contactRectangle1} />
              <div className={styles.div}>0894958658</div>
              <div className={styles.div}>0894389979</div>
            </div>
          </div>
          <div className={styles.contactSection1}>
            <img
              className={styles.contactSectionIcon1}
              alt=""
              src="/contactsectionicon2.svg"
            />
            <div className={styles.contactSectionText1}>
              <div className={styles.callUs}>Message Us</div>
              <div className={styles.contactRectangle1} />
              <div className={styles.div}>SergiuLeanca.29@icloud.com</div>
            </div>
          </div>
          <div className={styles.contactSection1}>
            <img
              className={styles.iconLocation}
              alt=""
              src="/iconlocation3.svg"
            />
            <div className={styles.contactSectionText1}>
              <div className={styles.callUs}>Location</div>
              <div className={styles.contactRectangle1} />
              <div className={styles.div}>Cashel Co Tipperary</div>
              <div className={styles.div}>Dublin</div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottomBar}>
          <div className={styles.sergiuPainterDecorating3}>
            Sergiu Painter Decorating Copyright © 2023
          </div>
        </div>
      </div>
      {isBurgerMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeBurgerMenu}
        >
          <BurgerMenu onClose={closeBurgerMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DesktopContact;
