import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import DesktopHome from "./pages/DesktopHome";
import DesktopWorkExamples from "./pages/DesktopWorkExamples";
import DesktopServices from "./pages/DesktopServices";
import DesktopAbout from "./pages/DesktopAbout";
import DesktopContact from "./pages/DesktopContact";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "Tipperary Painter Decorating";
    let metaDescription = "House Painter and Decorating services in Cashel co. Tipperary and Dublin for individuals and businesses";

    switch (pathname) {
      case "/":
        title = "Tipperary Painter Decorating";
        metaDescription = "House Painter and Decorating services in Cashel co. Tipperary and Dublin for individuals and businesses";
        break;
      case "/desktop-work-examples":
        title = "Work Examples";
        metaDescription = "";
        break;
      case "/desktop-services":
        title = "Services";
        metaDescription = "";
        break;
      case "/desktop-about":
        title = "About";
        metaDescription = "";
        break;
      case "/desktop-contact":
        title = "Contact";
        metaDescription = "";
        break;
    }

    if (title) {  
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<DesktopHome />} />
      <Route path="/desktop-work-examples" element={<DesktopWorkExamples />} />
      <Route path="/desktop-services" element={<DesktopServices />} />
      <Route path="/desktop-about" element={<DesktopAbout />} />
      <Route path="/desktop-contact" element={<DesktopContact />} />
    </Routes>
  );
}
export default App;
