import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./BurgerMenu.module.css";
const BurgerMenu = ({ onClose }) => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate("/desktop-about");
  }, [navigate]);

  const onServicesClick = useCallback(() => {
    navigate("/desktop-services");
  }, [navigate]);

  const onWorkExamplesClick = useCallback(() => {
    navigate("/desktop-work-examples");
  }, [navigate]);

  const onContactClick = useCallback(() => {
    navigate("/desktop-contact");
  }, [navigate]);

  return (
    <div className={styles.burgerMenu}>
      <div className={styles.burgerMenuLinks}>
        <a className={styles.home} onClick={onHomeClick}>
          Home
        </a>
        <Link
          className={styles.about}
          to="/desktop-about"
          onClick={onAboutClick}
        >
          About
        </Link>
        <Link
          className={styles.about}
          to="/desktop-services"
          onClick={onServicesClick}
        >
          Services
        </Link>
        <Link
          className={styles.about}
          to="/desktop-work-examples"
          onClick={onWorkExamplesClick}
        >
          Work Examples
        </Link>
        <Link
          className={styles.about}
          to="/desktop-contact"
          onClick={onContactClick}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};

export default BurgerMenu;
